import { useState } from "react";
import { ApiEndpoint, ApiService } from "../service/api.service";

export function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState('');

  const handleSubmit = async () => {
    if (!name || !email || !message) {
      setErrorMessage('All fields are required.');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
      setSubmitting(true);

      try {
        const contactPayload = { name, email, message };
        const contactResponse: any = await ApiService.post(ApiEndpoint.CONTACT, contactPayload);
        setSubmitted(true);
        setResponse(contactResponse);
      } catch (error) {
        setErrorMessage('Something went wrong. Please try again later.');
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <section className="contact">
      <h1 className="contact-title">Get in Touch</h1>
      <p className="contact-description">We would love to hear from you!</p>
      <div className="contact-form-section" style={{ maxWidth: 360 }}>
        {!submitted ? (
          <div className="box-brown-outer left mb-10">
            <div className="box-brown">
              <div className="contact-form">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                  disabled={submitting}
                />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="xyz@example.com"
                  required
                  disabled={submitting}
                />
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                  required
                  disabled={submitting}
                />
                {errorMessage && (
                  <span style={{ color: 'red', display: 'block', margin: '12px 0' }}>{errorMessage}</span>
                )}
                <button
                  onClick={handleSubmit}
                  className={`${submitting ? 'disabled' : ''} btn btn-nav btn-blue btn-blue-border`}
                  disabled={submitting}
                >
                  {submitting ? 'Sending...' : 'Send Message'}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="box-primary-outer">
            {response ? (
              <h2>{response}</h2>
            ) : (
              <span>Something went wrong. Please try again later.</span>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
