import Create from '../asset/img/artwork/create.png';
import Inspire from '../asset/img/artwork/inspire.png';
import Refine from '../asset/img/artwork/refine.png';

const FeaturePage = () => {
  const features = [
    {
      title: 'Inspire',
      description: `Let's get you Inspired! We'll help you generate ideas and inspiration for your next project. By answering a few questions, we'll be able to narrow down the type of script you're looking for. From there, we'll provide you with a range of ideas to get your creative juices flowing.`,
      image: Inspire,
    },
    {
      title: 'Refine',
      description: `Refine your ideas and bring your characters and settings to life with simple questions designed to help you define your story. Get one step closer to your finished script with our Refine stage.`,
      image: Refine,
    },
    {
      title: 'Create',
      description: `Dive into the creative process and start shaping your masterpiece. Let ScriptSmithy assist you with tools and guidance to craft your script at warp speed.`,
      image: Create,
    }
  ];

  return (
    <section className="feature-page mb-40">
      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="box-brown-outer left mb-10">
            <div className="box-brown">
              <div className="left">
                <img src={feature.image} width={200} alt={`Artwork depicting ${feature.title}`} />
              </div>
              <div className="right">
                <h2>{feature.title}</h2>
                <p>{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

    </section>
  );
};

export default FeaturePage;
