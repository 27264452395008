import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
} from "react-router-dom";
import { Background } from './layout/background';
import { Header } from './layout/header';
import { Footer } from './layout/footer';
import { HomePage } from './page/home';
import { ContactPage } from './page/contact';
import { NewProjectPage } from './page/project/new';
import { InspireProjectPage } from './page/project/inspire';

import './asset/css/variables.css';
import './asset/css/fonts.css';
import './asset/css/helpers.css';
import './asset/css/layout.css';
import './asset/css/buttons.css';
import './asset/css/boxes.css';
import './asset/css/tabs.css';
import './asset/css/accordions.css';
import './asset/css/form.css';
import './asset/css/home.css';
import { RefineProjectPage } from './page/project/refine';
import { PricingPage } from './page/pricing';
import { BuildProjectPage } from './page/project/build';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth } from './component/auth';
import { NotFoundPage } from './page/404';
import FeaturePage from './page/features';

const router = createBrowserRouter([
    {
        element: (

            <React.StrictMode>
                <Background />
                <div className="container">
                    <Auth0Provider
                        domain="thyreality.uk.auth0.com"
                        clientId="JMibUV5SGTGru9LMHUQ9bIRlvI5NOiun"
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                        }}
                    >
                        <Auth>
                            <>
                                <div className="main">
                                    <Header />
                                    <div className="content">
                                        <Outlet />
                                    </div>
                                </div>
                                <Footer />
                            </>
                        </Auth>
                    </Auth0Provider>
                </div>
            </React.StrictMode>
        ),
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/pricing",
                element: <PricingPage />,
            },
            {
                path: "/features",
                element: <FeaturePage />,
            },
            {
                path: "/project/new",
                element: <NewProjectPage />,
            },
            {
                path: "/project/inspire",
                element: <InspireProjectPage />,
            },
            {
                path: "/project/refine",
                element: <RefineProjectPage />,
            },
            {
                path: "/project/build",
                element: <BuildProjectPage />,
            },
            {
                path: "/contact",
                element: <ContactPage />
            },
            {
                path: "*",
                element: <NotFoundPage />,
            },
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
